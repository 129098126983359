<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Info</h2>
        <nav v-if="user.level >= 2" class="flex items-end gap-2">
            <!-- <FormButton v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil" :to="{name: 'market-homes-edit', params: {id: community.id}}">
                Edit
            </FormButton> -->
            <FormButton @click="edit()" v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil">
                Edit
            </FormButton>
            <template v-else>
                <FormButton @click="cancel()" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300" icon="fa-cancel">
                    Cancel
                </FormButton>
                <FormButton @click="save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-save">
                    Save
                </FormButton>
            </template>
        </nav>

    </div>
    <p class="text-sm text-oakwood-gray bg-oakwood-gray-50 p-2 mt-4 rounded border border-oakwood-gray-300">
        <span class="far fa-circle-exclamation mr-2"></span>
        This data is inherited from Kova. Any changes made here will not update Kova, but will override those values.
    </p>
    <dl class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-if="!editing">
        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Community Name</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ community.meta?.info?.name }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Description</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ community.meta?.info?.description }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Address</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <p v-if="community.meta?.info?.address?.street1">{{ community.meta?.info?.address?.street1 }}</p>
            <p v-if="community.meta?.info?.address?.street2">{{ community.meta?.info?.address?.street2 }}</p>
            <p v-if="community.meta?.info?.address?.city && community.meta?.info?.address?.state && community.meta?.info?.address?.zip">{{ community.meta?.info?.address?.city }} {{ community.meta?.info?.address?.state }} {{ community.meta?.info?.address?.zip }}</p>  
        </dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Geolocation</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <p>Latitude: {{ community.meta?.info?.geo?.lat }}º</p>
            <p>Longitude: {{ community.meta?.info?.geo?.lng }}º</p>
        </dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Community Website</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4"><a class="text-oakwood-blue-500 hover:underline" :href="community.meta.info.website" target="_blank" title="Visit this home's website">{{ community.meta.info.website }}</a></dd>
    </dl>

    <fieldset class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-else-if="user.level >= 2">
        <legend class="sr-only">Custom Properties</legend>

        <label for="info.name" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Community Name</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="info.name" v-model="info.name"/>
        </div>

        <label for="info.description" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Description</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <textarea class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400 min-h-48" id="info.description" v-model="info.description"></textarea>
        </div>

        <h3 class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Community Address</h3>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 grid lg:grid-cols-3 gap-2">
            <p class="lg:col-span-3 grid gap-2">
                <label for="info.address.street1" class="text-sm leading-5 font-medium text-oakwood-gray-500">Street Address 1</label>
                <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="info.address.street1" v-model="info.address.street1"/>
            </p>
            <p class="lg:col-span-3 grid gap-2">
                <label for="info.address.street2" class="text-sm leading-5 font-medium text-oakwood-gray-500">Street Address 2</label>
                <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="info.address.street2" v-model="info.address.street2"/>
            </p>
            <p class="grid gap-2">
                <label for="info.address.city" class="text-sm leading-5 font-medium text-oakwood-gray-500">City</label>
                <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="info.address.city" v-model="info.address.city"/>
            </p>
            <p class="grid gap-2">
                <label for="info.address.state" class="text-sm leading-5 font-medium text-oakwood-gray-500">State</label>
                <SelectField id="info.address.state" class="w-full" v-model="info.address.state">
                    <option :value="null">Select a State...</option>
                    <option v-for="state, i in states" :key="`state-${i}`" :value="state.value">{{ state.label }}</option>
                </SelectField>
            </p>
            <p class="grid gap-2">
                <label for="info.address.zip" class="text-sm leading-5 font-medium text-oakwood-gray-500">Zip Code</label>
                <NumberField id="info.address.zip" width="w-full" :min="0" :max="99999" :arrows="false" v-model="info.address.zip" />
            </p>
        </div>

        <p class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Geolocation</p>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4 grid lg:grid-cols-6 gap-2">
            <p class="grid gap-2">
                <label for="info.geo.lat" class="text-sm leading-5 font-medium text-oakwood-gray-500">Latitude</label>
                <NumberField suffix="º" id="info.geo.lat" width="w-full" step="0.00000000000001" :max="90" :min="-90" v-model="info.geo.lat" />
            </p>
            <p class="grid gap-2">
                <label for="info.geo.lng" class="text-sm leading-5 font-medium text-oakwood-gray-500">Longitude</label>
                <NumberField suffix="º" id="info.geo.lng" width="w-full" step="0.00000000000001" :max="180" :min="-180" v-model="info.geo.lng" />
            </p>
        </div>

        <label for="info.website" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Community Website</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <URLField id="info.website" v-model="info.website" class="w-full" />
        </div>

    </fieldset>
       
</template>

<script>
import { formatToCurrency } from '@/helpers/numberHelpers';
import {FormButton, URLField, SelectField, NumberField} from '@/components/form';
import {states} from '@/helpers/globals';
import community from '@/models/community';
import { mapState } from 'pinia';
import { useAuthStore } from '@/stores/auth';

export default {
    data() {
        return {
            info: {
                ...community.meta.info
            },
            states,
            editing: false
        }
    },
    methods: {
        edit() {
            if(this.user.level < 2) return;
            this.editing = true;
            let info = JSON.parse(JSON.stringify(this.community.meta.info));
            this.info = {
                ...this.info,
                ...info
            }
        },
        cancel() {
            this.editing = false;
        },
        save() {
            if(this.user.level < 2) return;
            let community = this.community;
            community.meta.info = {
                ...community.meta.info,
                ...this.info
            }
            this.$emit('update', community);
            this.cancel();
        },
        formatToCurrency
    },
    computed: {
        ...mapState(useAuthStore, ['user'])
    },
    emits: [
        'update'
    ],
    props: {
        community: {
            type: Object,
            required: true
        }
    },
    components: {
        FormButton,
        URLField,
        SelectField,
        NumberField
    }
}
</script>@/components/form