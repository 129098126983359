<template>
    <nav class="text-oakwood-gray-700 flex items-center justify-between">
        <div class="hidden sm:block">
            <p v-if="count > 0" class="text-sm leading-5 text-gray-700">
                Showing
                <span class="font-medium">{{ from + 1 }}</span>
                to
                <span class="font-medium">{{ to }}</span>
                of
                <span class="font-medium">{{ count }}</span>
                results.
            </p>
            <p v-else class="text-sm leading-5 text-gray-700">
                No results found.
            </p>
        </div>
        <div class="flex-1 flex justify-between sm:justify-end">
            <button :disabled="page == 1" @click="$emit('flip', 'prev')" type="button"
                :class="{ 'opacity-50 cursor-not-allowed': page == 1 }"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                Previous
            </button>
            <button :disabled="!max || page == max" @click="$emit('flip', 'next')" type="button"
                :class="{ 'opacity-50 cursor-not-allowed': !max || page == max }"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                Next
            </button>
        </div>
    </nav>
</template>

<script>
export default {
    inject: [
        'page',
        'from',
        'to',
        'count',
        'limit'
    ],
    emits: [
        'flip'
    ],
    computed: {
        max() {
            return Math.ceil(this.count / this.limit);
        }
    }
}
</script>