import { getRange } from "@/helpers/numberHelpers";

const Floorplan = class {

    floorplan;

    constructor(data) {
        this.data = data;
        return this.data;
    }

    get data() {
        return this.floorplan;
    }

    set data(data) {
        this.floorplan = {
            id: data?.id || null,
            status: data?.status || 'new',
            community_model_rid: data?.community_model_rid || null,
            collection_id: data?.collection_id || null,
            collection: data?.collection || {},
            meta: {
                interactives: data?.meta?.interactives || [],
                videos: data?.meta?.videos || [],
                info: {
                    name: data?.meta?.info?.name || '',
                    description: data?.meta?.info?.description || '',
                    // status: data?.meta?.info?.status || "N/S",
                    sqft: {
                        min: parseInt(data?.meta?.info?.sqft?.min || data?.meta?.info?.sqft || 0),
                        max: parseInt(data?.meta?.info?.sqft?.max || data?.meta?.info?.sqft || 0)
                    },
                    bedrooms: {
                        min: parseInt(data?.meta?.info?.bedrooms?.min || data?.meta?.info?.bedrooms || 0),
                        max: parseInt(data?.meta?.info?.bedrooms?.max || data?.meta?.info?.bedrooms || 0)
                    },
                    bathrooms: {
                        min: {
                            full: parseInt(data?.meta?.info?.bathrooms?.min?.full || data?.meta?.info?.bathrooms?.full || 0),
                            half: parseInt(data?.meta?.info?.bathrooms?.min?.half || data?.meta?.info?.bathrooms?.half || 0)
                        },
                        max: {
                            full: parseInt(data?.meta?.info?.bathrooms?.max?.full || data?.meta?.info?.bathrooms?.full || 0),
                            half: parseInt(data?.meta?.info?.bathrooms?.max?.half || data?.meta?.info?.bathrooms?.half || 0)
                        }
                    },
                    garages: {
                        min: parseInt(data?.meta?.info?.garages?.min || data?.meta?.info?.garages || 0),
                        max: parseInt(data?.meta?.info?.garages?.max || data?.meta?.info?.garages || 0)
                    },
                    price: {
                        min: parseInt(data?.meta?.info?.price?.min || data?.meta?.info?.price || 0),
                        max: parseInt(data?.meta?.info?.price?.max || data?.meta?.info?.price || 0)
                    },
                    headline: data?.meta?.info?.headline || '',
                    website: data?.meta?.info?.website || '',
                }
            },
            created_at: data?.created_at || null,
            updated_at: data?.created_at || null,
            kova: data?.kova || {},
        };
    }
}

const helpers = {
    
    garageRange() {
        let min = this.floorplan?.meta?.info?.garages?.min || 0;
        let max = this.floorplan?.meta?.info?.garages?.max || 0;
        return getRange(min,max);
    },

    fullBathRange() {
        let min = this.floorplan?.meta?.info?.bathrooms?.min?.full || 0;
        let max = this.floorplan?.meta?.info?.bathrooms?.max?.full || 0;
        return getRange(min,max);
    },

    halfBathRange() {
        let min = this.floorplan?.meta?.info?.bathrooms?.min?.half || 0;
        let max = this.floorplan?.meta?.info?.bathrooms?.max?.half || 0;
        return getRange(min,max);
    },

    bedRange() {
        let min = this.floorplan?.meta?.info?.bedrooms?.min || 0;
        let max = this.floorplan?.meta?.info?.bedrooms?.max || 0;
        return getRange(min,max);
    },

    priceRange() {
        let min = this.floorplan?.meta?.info?.price?.min || 0;
        let max = this.floorplan?.meta?.info?.price?.max || 0;
        return getRange(min,max,true);
    },

    sqftRange() {
        let min = this.floorplan?.meta?.info?.sqft?.min || 0;
        let max = this.floorplan?.meta?.info?.sqft?.max || 0;
        return getRange(min,max);
    },
}

export {
    Floorplan as default,
    helpers
};