<template>
    <div class="flex flex-col lg:flex-row justify-between items-center">
        <h2 class="text-xl font-medium text-oakwood-blue-600">Info</h2>
        <nav v-if="user.level >= 2" class="flex items-end gap-2">
            <!-- <FormButton v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil" :to="{name: 'market-homes-edit', params: {id: collection.id}}">
                Edit
            </FormButton> -->
            <FormButton @click="edit()" v-if="!editing" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-pencil">
                Edit
            </FormButton>
            <template v-else>
                <FormButton @click="cancel()" class="bg-oakwood-gray-200 text-oakwood-gray-900 hover:bg-oakwood-gray-300" icon="fa-cancel">
                    Cancel
                </FormButton>
                <FormButton @click="save" class="bg-oakwood-blue-600 text-white hover:bg-oakwood-blue-700" icon="fa-save">
                    Save
                </FormButton>
            </template>
        </nav>

    </div>
    <p class="text-sm text-oakwood-gray bg-oakwood-gray-50 p-2 mt-4 rounded border border-oakwood-gray-300">
        <span class="far fa-circle-exclamation mr-2"></span>
        This data is inherited from Kova. Any changes made here will not update Kova, but will override those values.
    </p>
    <dl class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-if="!editing">
        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Collection Name</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ collection.meta?.info?.name }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Description</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">{{ collection.meta?.info?.description }}</dd>

        <dt class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Collection Website</dt>
        <dd class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4"><a class="text-oakwood-blue-500 hover:underline" :href="collection.meta.info.website" target="_blank" title="Visit this home's website">{{ collection.meta.info.website }}</a></dd>
    </dl>

    <fieldset class="grid grid-cols-1 lg:grid-cols-4 mt-8" v-else-if="user.level >= 2">
        <legend class="sr-only">Custom Properties</legend>

        <label for="info.name" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Collection Name</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <input type="text" class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400" id="info.name" v-model="info.name"/>
        </div>

        <label for="info.description" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Description</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <textarea class="border border-oakwood-gray-200 w-full p-2 rounded focus:border-oakwood-blue-400 min-h-48" id="info.description" v-model="info.description"></textarea>
        </div>

        <label for="info.website" class="text-sm leading-5 font-medium text-oakwood-gray-500 border-t border-t-oakwood-gray-100 py-4">Collection Website</label>
        <div class="text-sm leading-5 text-oakwood-gray-900 lg:col-span-3 lg:border-t border-t-oakwood-gray-100 py-4">
            <URLField id="info.website" v-model="info.website" class="w-full" />
        </div>

    </fieldset>
       
</template>

<script>
import { formatToCurrency } from '@/helpers/numberHelpers';
import {FormButton, URLField, SelectField} from '@/components/form';
import {states} from '@/helpers/globals';
import collection from '@/models/collection';
import {floorplan} from '@/helpers/globals';
import { mapState } from 'pinia';
import { useAuthStore } from '@/stores/auth';

export default {
    data() {
        return {
            info: {...collection.meta.info},
            states,
            editing: false,
            statuses: floorplan.ModelStatus
        }
    },
    methods: {
        edit() {
            if(this.user.level < 2) return;
            this.editing = true;
            let info = JSON.parse(JSON.stringify(this.collection.meta.info));
            this.info = {
                ...this.info,
                ...info
            }
        },
        cancel() {
            this.editing = false;
        },
        save() {
            if(this.user.level < 2) return;
            let collection = this.collection;
            collection.meta.info = {
                ...collection.meta.info,
                ...this.info
            }
            this.$emit('update', collection);
            this.cancel();
        },
        formatToCurrency
    },
    computed: {
        ...mapState(useAuthStore, ['user'])
    },
    emits: [
        'update'
    ],
    props: {
        collection: {
            type: Object,
            required: true
        }
    },
    components: {
        FormButton,
        URLField,
        SelectField
    }
}
</script>@/components/form