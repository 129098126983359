const formatToCurrency = (value) => {
    if (typeof value !== 'number') {
      return value;
    }
  
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });
  
    return formatter.format(value);
};

const formatToNumber = (value) => {
  if (typeof value !== 'number') {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0
  });

  return formatter.format(value);
};
  
const getRange = (min,max,currency) => {
  currency = currency || false;
  let retMin = currency ? formatToCurrency(min) : formatToNumber(min);
  let retMax = formatToNumber(max);
  return min === max ? retMin : `${retMin} – ${retMax}`;
};

export { formatToCurrency, formatToNumber, getRange }